$primary-color: #fbbc00;
$secondary-color: #212529;

.App {
  background: white;

}


html,
body {
  margin: 0;
  padding: 0;
  //padding-top: 43px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-attachment: fixed;
  width: 100%;
  font-family: "Montserrat", sans-serif !important;

}

.copertina {
  animation: fadeIn;
  animation-duration: 2s;
  width: 100%;
  background-repeat: no-repeat;
  /*background-size: 100% 100%;*/
  background-size: cover;
  background-position: center;
  border: 0px;
  overflow: hidden;

  img {
    object-fit: cover;
    max-height: 800px
  }

  .carousel {
    max-height: 700px;
  }

}

.carousel-caption {
  background-color: rgba(0, 0, 0, 0.5);
  /* Nero con 50% di opacità */
  padding: 20px;
  /* Aggiungi spazio attorno al testo per migliorare la leggibilità */
  border-radius: 5px;
  /* Arrotonda gli angoli per un aspetto più elegante */
  color: white;
  /* Colore del testo bianco per contrastare con lo sfondo nero */
  font-size: 22px;

  @media screen and (max-width: 767px) {
    // per dispositivi mobili
    font-size: 12px;
  }
}




.error-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 83vh;
  text-align: center;
  color: #002146;
  flex-direction: column;

}


.logo-nav {
  width: 60px;
  height: 60px;

  @media screen and (max-width: 767px) {
    // per dispositivi mobili
    width: 45px;
    height: 45px;
  }

  animation: bounce;
  animation-duration: 2s;
}


.nome-logo {
  padding-left: 5px;
  font-weight: 500;
  text-transform: uppercase;

  @media screen and (max-width: 767px) {
    // per dispositivi mobili
    font-size: 14px;
  }

  @media screen and (min-width: 768px) {
    // per desktop
    font-size: 18px;
  }
}


 

.video-home  {
  position: relative;
  padding-bottom: 50.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;

  border: solid $primary-color 5px;
  border-radius: 5px;
  @media screen and (max-width: 767px) {
    // per dispositivi mobili
    padding-bottom: 45%;
  }
  @media screen and (min-width: 768px) {
    // per desktop
    padding-bottom: 52.5%;
  }
 
}

.video-home  iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
 


//candidati

.candidati {
  padding: 30px 0;
  text-align: center;
  background-color: $secondary-color;
  font-size: 14px;

  .box-utente {
    text-align: center;
    padding: 5px;
    transition: transform 0.2s ease-in-out;
    div {
      background: whitesmoke;
      border-radius: 5px;
      overflow: hidden;
    }
  }
}




/*punti slick*/
.slick-dots li.slick-active button:before {
  color: $primary-color;
}

.slick-dots li button:before {
  color: #fff;
}





.candidato {
  margin-bottom: 10px;
  img {
    /*border-radius: 50%;
    width: 100%;
    padding-bottom: 5px;
    max-height: 200px;
    object-fit: fill;*/

    border-radius: 5%;
    width: 100%;
    margin-bottom: 5px;
    height: 140px;
    object-fit: cover;

    @media screen and (min-width: 768px) {
      // per desktop
      height: 240px;
    }
  }
  transition: transform 0.2s ease-in-out;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;

  @media screen and (max-width: 767px) {
    // per dispositivi mobili
    font-size: 12px;
  }

  @media screen and (min-width: 768px) {
    // per desktop
    font-size: 18px;
  }
  &:active, &:hover {
    transform: scale(1.1);
  }

  animation: bounceInDown;
  animation-duration: 2s;
}



.descrizione-candidati {
  padding: 30px 0;
  background-color: $secondary-color;
  color: white;
  font-weight: bold;
  overflow: hidden;
}

.testo-candidato {
  color: white;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  padding: 30px 0;
 white-space: pre-line;
 flex-direction: column;
 @media screen and (min-width: 768px) {
  // per desktop
  font-size: 18px;
}
 a {
  color:$primary-color;
  margin: 5px;
 }
}

.nome-candidato-list{
  font-size: 10px;
  
  @media screen and (min-width: 768px) {
    // per desktop
    font-size: 16px;
  }
}


.modal-caricamento {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 100px;
  text-align: center;
  color: whitesmoke;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5) !important;
  z-index: 99999;
  position: fixed;
  top: 0;
  right: 0;
}

//header
.navbar-nav .nav-link{
  text-align: center;
  padding: 5px  25px !important;
  //border-bottom: 3px solid #9b9d9e !important;
  font-size: 14px;
}
.navbar-nav .nav-link.active {
  //color: $primary-color !important;
  border-bottom: 3px solid $primary-color !important;
  animation: pulse;
  animation-duration: 2s;
}
.navbar{
  font-weight: 500;
  
  /*animation: fadeInDown;
  animation-duration: 2s;*/

  &.fixed-menu {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10;
    animation: fadeInDown;
    animation-duration: 1s;
  }
}

//footer
.footer {
  background-color: $primary-color;
  color: white;
  padding: 50px 0;
  text-align: center;
  font-weight: bold;
}

.footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
  @media screen and (min-width: 768px) {
    // per desktop
    font-size: 16px;
  }
  span{
    margin-bottom: 5px;
  }
}



.foto-gallery{
  border: 5px solid #fbbc00;
  //border: 4px solid #f3b628;
  &:active, &:hover {
    transform: scale(1.1)!important;
  }
}

.invisible {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

.barra-titolo{
  background: $primary-color;
  color: white;
   margin-bottom: 40px;
   padding: 15px 0;
   text-align: center;
  /* border-top: 1px solid $secondary-color;
   border-bottom: 1px solid  $secondary-color;*/


   /* font-style: italic;
    -webkit-text-stroke-color: #000;
    stroke: #000;
    text-shadow: -5px 4px 11px rgba(241.5, 237.40209045410157, 237.40209045410157, 0.48);*/
}


//MANIFESTO

.manifesto {
  li{
    margin-bottom: 20px;
  }
}
.titolo-manifesto{
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 40px;
  font-weight: 500;
  text-transform: uppercase;
  img{
    width: 80px;
     margin: 5px;
  }

  

  padding:50px 0;
}

.box-testo {
  position: relative;
  font-size: 14px;
  color: white;
  background: $secondary-color;
  padding: 15px;
  border-radius: 5px;
  margin-bottom: 20px; /* Aggiungo margine inferiore per spazio al triangolino */
  text-shadow: -5px 4px 11px rgba(241.5, 237.40209045410157, 237.40209045410157, 0.48);
}


/* Triangolo */
.box-testo::after {
  content: "";
  position: absolute;
  bottom: 0px;
  right: -10px;
  border-width: 0 10px 19px 10px;
  border-color: transparent transparent $secondary-color transparent;
  border-style: solid;
  z-index: 1
}



//contattaci
.contact-form-container {
  text-shadow: -5px 4px 11px rgba(241.5, 237.40209045410157, 237.40209045410157, 0.48);
 // background-color: black;
  padding: 50px 0; /* Aggiungi spazio sopra e sotto il form */
  background: linear-gradient(to bottom, #212529, #6c757d);
}

.contact-form {
  background-color: #333;
  padding: 20px;
  border-radius: 10px;
}

.contact-form label {
  color: white;
}

.contact-form input,
.contact-form textarea {
  background-color: #555;
  color: white;
  border: none;
}

.contact-form input::placeholder,
.contact-form textarea::placeholder {
  color: #ccc;
}

.contact-form button {
  background-color: #fbbc00;
  border: none;
  padding: 5px 15px;
  font-size: 18px;
  width: 100%;
}

.contact-form button:hover {
  background-color: #0056b3;
}



 
.scroll-to-top-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: gray;
  color: #fff;
  border: none;
  outline: none;
  cursor: pointer;
  transition: background-color 0.3s;
  text-align: center;

  i {
      font-size: 24px;
  }

  &:hover {
      background-color:  $primary-color;;
  }

 /* @media screen and (min-width: 768px) {
    display: none;
  }*/
}



.box-sezione{
  margin-top: 20px;
  font-size: 10px;
  background-color:  $primary-color;; 
  //padding: 25px;
  width: 100%;
  height: 100px;
  font-weight: bold;
  color:white;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center!important;
  border: 1px solid black;
}





.quartiere{
  margin-bottom: 10px;
  border-radius: 15px;
  img {
    border-radius: 5%;
    width: 100%;
    margin-bottom: 5px;
    height: 140px;
    object-fit: contain;
    @media screen and (min-width: 768px) {
      // per desktop
      height: 200px;
    }
  }

  transition: transform 0.2s ease-in-out;
  font-size: 10px;
  font-weight: bold;
  cursor: pointer;

  @media screen and (max-width: 767px) {
    // per dispositivi mobili
    font-size: 10px;
  }

  @media screen and (min-width: 768px) {
    // per desktop
    font-size: 18px;
  }
  &:active, &:hover {
    transform: scale(1.1);
    color:  $primary-color;; 
  }

  animation: bounceInDown;
  animation-duration: 2s;
 
  
}
 